.bot-modal {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: 99dvh;
    transform: translateY(100%);
    transition: transform 0.3s ease-out;
    color: var(--primary-color);
    z-index: 1000;
    min-height: 300px;
    height: auto;
    overflow-y: scroll;
    background: #fff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    // display: none;  /* Hidden by default */
    .drag-handle {
        width: 50px;
        height: 5px;
        background: #ccc;
        margin: 0 auto;
        border-radius: 5px;
    }
    #venue-sheet-venue-name {
        font-size: clamp(18px, 5vw, 24px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }
    h2 {
        font-size: 18px;
        line-height: 1.4;
    }
    p {
        margin-bottom: 10px;
    }
}

.bot-modal.modal-bottom {
    transform: translateY(0);
    opacity: 1;
    // display: block;  /* Ensure it's visible when in bottom position */
}

.bot-modal.modal-hidden {
    transform: translateY(100%);
    /* We're not setting display: none here, to allow for transitions */
}

.bot-modal.modal-transition {
    transition: transform 0.3s ease-out;
}

.bot-modal.modal-bottom {
    transform: translateY(calc(var(--drag-offset, 0px)));
}

.bot-modal-header {
    position: sticky;
    top: 0;
    background: #fff;
}

.bot-modal-title {
    padding: 0 0 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
        margin: 0;
    }
}

.bot-modal-grabber {
    padding: 10px;
    cursor: grab;
    /*   position: sticky; */
    /*   top: 0; */
    /*   background: #f4f4f4;
  position: absolute;
  width: 100%; */
}

#trigger-btn {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
}

.close-modal {
    color: var(--foreground);
    // color: var(--background);
    border: none;
    padding: 5px;
    font-size: 30px;
    line-height: 22px;
    font-weight: 400;
    -webkit-appearance: none;
    -moz-appearance: none;
    // background-color: transparent;
    background-color: #fff;
    border-radius: 100px;
    height: 30px;
    width: 40px;
    text-align: right;
    margin: 5px 10px;
    box-sizing: border-box;
    // box-shadow: 0 0 3px rgba(0, 0, 0, .35);
}

#venueSheet.bot-modal {
    height: 99dvh;
}

#sheet-add.bot-modal {
    height: auto;
    margin: 0 10px;
    width: calc(100% - 20px);
    min-height: 0;
    border-radius: 15px;
    &.modal-hidden {
        // transform: translateY(calc(var(--drag-offset, 0px)));
        transform: translateY(calc(100% + 90px));
    }
    &.modal-bottom {
        transform: translateY(-90px);
    }
    // .sheet-footer {
    //   padding: 0 16px;
    //   width: 100%;
    //   span {
    //     position: relative;
    //     display: block;
    //     // background-color: red;
    //     // height: 5px;
    //     &::after {
    //       content: '';
    //       width: 0;
    //       height: 0;
    //       position: absolute;
    //       top: 0;
    //       left: 50%;
    //       margin-left: -16px;
    //       border-left: 16px solid transparent;
    //       border-right: 16px solid transparent;
    //       border-top: 16px solid var(--background);
    //     }
    //   }
    // }
}