$assets-path: "../img";
$screen-sm: 768px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1600px;

:root {
    --btn-height: 40px;
    --primary-color: #1f0f47; //171, 3, 144; 
    --purple2: #AB0390;
    --purple3: #6644EE;
    --red: 255, 41, 76; //#ff294c;
    --green: 27, 188, 155; //#1bbc9b; or //18B696
    --blue: #1DA1F2;
    --disabled-grey: #666;
    --text-dark: #222;
    --text-light: #fff;
    --disabled-grey: #666;
    --form-border-color: #999;
    --input-border-radius: 5px;
}

.w-100 {
    width: 100%;
}

.d-none {
    display: none;
}

.d-flex {
    display: flex;
}

.pad {
    padding: 16px;
}
.pad-lr {
    padding: 0 16px;
}
.pad-tb {
    padding: 16px 0;
}
.mb-0 {
    margin-bottom: 0;
}
.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}