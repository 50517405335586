/* CSS */
.accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }
  
  .accordion-content.show {
    max-height: 800px; /* Default max-height */
  }
  
  .trigger {
    cursor: pointer;
  }
  
  .trigger.active {
    font-weight: bold;
  }
  