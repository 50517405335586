#content-map {
    // border: 2px solid red;
    background-color: var(--primary-color) !important;
}

#map {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    border: none !important;
    outline: none !important;
    background-color: var(--primary-color) !important;
    // >div {
    //     background-color: var(--primary-color) !important;
    // }
}

.gm-style iframe+div {
    border: none !important;
    outline: none !important;
    // background-color: var(--primary-color) !important;
}

.info-window-content {
    color: #000;
}

.map-ui {
    position: fixed;
    top: 0;
    z-index: 2;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%);
}

#center-control {
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    text-indent: -999em;
    // position: absolute;
    // top: 10px;
    // right: 10px;
    width: 30px;
    height: 30px;
    overflow: hidden;
    background: #fff url(#{$assets-path}/location.svg) 50% 50% no-repeat;
    background-size: 20px auto;
}

#help-control {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    // margin-left: auto;
    // margin-right: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    span {
        color: var(--primary-color);
        font-size: 20px;
        font-weight: bold;
    }
}

.venue-marker {
    width: 30px;
    height: 30px;
    position: relative;
    // transition: all 0.4s ease-out;
    &.event {
        // border: 2px solid red;
        // background-color: red;
        &:before {
            display: inline-block;
            content: "";
            border-radius: 50%;
            height: 18px;
            width: 18px;
            position: absolute;
            z-index: 2;
            top: -12px;
            left: 6px;
            background: url(#{$assets-path}/event-marker.svg) 50% 50% no-repeat;
            background-size: contain;
        }
        &::after {
            position: absolute;
            top: -33px;
            left: 5px;
            font-size: 20px;
            z-index: 1;
        }
        &.karaoke {
            &::after {
                content: "🎤";
            }
        }
        &.dj_dance {
            &::after {
                content: '🎧';
                // content: '🎵';
            }
        }
        &.sports {
            &::after {
                content: '📺';
            }
        }
        &.live_music {
            &::after {
                content: "🎸";
            }
        }
        &.quiz_trivia {
            &::after {
                content: "🤔";
            }
        }
        &.comedy {
            &::after {
                content: "😂";
            }
        }
    }
    &.positive {
        /* choose the green fraident for the SVG path */
        path {
            fill: url(#positive);
        }
        .pulse {
            animation-duration: 1s;
            background: green;
        }
    }
    &.negative {
        path {
            fill: url(#negative);
        }
        .pulse {
            animation-duration: 2s;
            animation-delay: 1.5s;
            background: red;
        }
    }
    &.neutral {
        path {
            fill: url(#neutral);
        }
        .pulse {
            animation-duration: 1.5s;
            animation-delay: 2s;
            background: orange;
        }
    }
}

.pulse {
    opacity: 0;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 8px;
    bottom: -14px;
    z-index: -1;
    transform: scale(0.5);
    transform-origin: center center;
    animation: pulse-me 2s linear infinite;
    animation-delay: 1s;
}

@keyframes pulse-me {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    50% {
        opacity: 0.8;
    }
    70% {
        opacity: 0.3;
    }
    100% {
        transform: scale(4);
        opacity: 0;
    }
}

// this shows users location
.custom-marker {
    background-color: #fff;
    border-radius: 50%;
    color: white;
    padding: 10px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    position: relative;
    &.active {
        background-color: red;
    }
    &:before {
        background-color: var(--purple2);
        border-radius: 50%;
        height: 15px;
        width: 15px;
        animation: user-location-pulse 2s infinite;
        content: "";
        position: absolute;
    }
}

.users-location {
    position: relative;
    background-color: var(--purple2);
    border-radius: 50%;
    height: 15px;
    width: 15px;
    &:before {
        background-color: var(--purple2);
        border-radius: 50%;
        height: 15px;
        width: 15px;
        // animation: user-location-pulse 4s infinite;
        content: "";
        position: absolute;
    }
    &:after {
        border: 2px solid #fff;
        border-radius: 50%;
        box-shadow: 0 0 3px rgba(0, 0, 0, .35);
        box-sizing: border-box;
        content: "";
        height: 19px;
        left: -2px;
        position: absolute;
        top: -2px;
        width: 19px
    }
}

@keyframes user-location-pulse {
    0% {
        opacity: 1;
        transform: scale(1)
    }
    70% {
        opacity: 0;
        transform: scale(3)
    }
    to {
        opacity: 0;
        transform: scale(1)
    }
}

.fetching-vibes-loader {
    width: 100%;
    display: flex;
    position: absolute;
    left: 0;
    bottom: -90px;
    opacity: 0;
    justify-content: center;
    z-index: 2;
    transition: all 0.4s ease-out;
    &.show {
        opacity: 1;
        bottom: 90px;
    }
    span {
        text-align: center;
        font-size: 12px;
        color: #fff;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        background: #111;
        gap: 3px;
        padding: 3px 6px 3px 3px;
        &::before {
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            background: url(#{$assets-path}/loading.svg) 50% 50% no-repeat;
            background-size: contain;
            // pointer-events: none
        }
    }
}

:root {
    --swiper-pagination-color: white;
    /* Set the active bullet color to white */
    --swiper-pagination-bullet-inactive-color: #fff;
    --swiper-pagination-bullet-opacity: 1;
    --swiper-pagination-bottom: 18px;
}

// .gallery {
//     padding: 0 20px;
//     display: grid;
//     box-sizing: border-box;
//     grid-auto-columns: -webkit-max-content auto;
//     grid-auto-columns: max-content auto;
//     grid-template-rows: auto auto;
//     grid-auto-flow: column;
//     grid-gap: 8px;
//     min-width: 100%;
//     img {
//         display: block;
//     }
// }
.swiper {
    border-radius: 10px 10px 0 0;
}

.swiper-slide {
    padding: 0;
    margin: 0 0 1rem 0;
    height: 240px !important;
    background-color: #eee;
    img {
        display: block;
        width: 100%;
        height: 100%;
        margin: auto;
        // border-radius: 10px;
        object-fit: cover;
    }
}

.opening-hours {
    font-size: 12px;
    margin: 10px 0;
    line-height: 20px;
}

// #venue-select {
//     display: none;
// }
.user-in-bar #venue-select {
    display: block;
}