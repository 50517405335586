h1,
h2,
h3,
h4,
h5 {
  color: var(--primary-color);
  margin: 0 0 1rem 0;
  font-weight: 700;
}

ul,
ol,
li {
  padding: 0;
  margin: 0;
}

li,
p {
  margin: 0 0 20px 0;
  display: block;
  line-height: 1.5;
  color: var(--primary-color);
}

// .badge {
//   color: #fff;
//   padding: 3px 5px;
//   background-color: var(--purple2);
//   border-radius: 30px;
//   font-size: 12px;
//   text-decoration: none;
//   white-space: nowrap;
//   flex-shrink: 0;
// }





.instructions {

  margin: 20px 0;

  li {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 0;
    margin: 0;
    border-top: 1px solid #eee;

    &:last-child {
      border-bottom: 1px solid #eee;
    }

    .instruction-icon {
      width: 2rem;
      height: 2rem;
      overflow: visible;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .venue-marker {
      svg {
        height: 100%;
        overflow: visible;
        width: auto;
      }
    }

    P {
      display: inline-block;
      vertical-align: middle;
      margin: 0;
    }

  }
}



.marker-example {
  width: 2rem;
  height: 2rem;
  display: block;
  border-radius: 100%;
  animation: pulsate 1.6s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  border: 1px solid var(--blue);
  background-color: var(--blue);


  &.good {
    border-color: rgb(var(--green));
    background-color: rgba(var(--green), 0.5);
  }

  &.bad {
    border-color: rgb(var(--red));
    background-color: rgba(var(--red), 0.5);

  }


}

.content {
  padding: 20px;

  h1,
  h2,
  h3,
  h4,
  p,
  a {
    color: var(--text-light);

  }
}