@import "variables";

.nav {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    padding: 16px 8px;
    align-items: flex-end;
    justify-content: center;
    // background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 40%, rgba(0, 0, 0, 0) 100%);

    ul {
        width: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
        width: auto;
        transition: opacity 0.4s ease-out;
        border-radius: 30px;
        overflow: hidden;
        padding: 0;
        margin: 0;
        background: var(--primary-color);
        border: 1px solid rgba(255, 255, 255, 0.1);
    }

    li {
        position: relative;
        color: #fff;
        padding: 10px 36px;
        margin: 0;
        list-style: none;
        line-height: 1;
        justify-content: center;
        text-align: center;
        display: flex;
        align-items: center;
        flex-basis: 100%;
        // height: 56px;
        border-right: 1px solid rgba(255, 255, 255, 0.1);
        flex-direction: column;
        gap: 5px;
        overflow: hidden;

        &.active {
            // background-color: #6644EE;

            &:after {
                content: '';
                width: 100%;
                height: 100%;
                display: flex;
                margin: auto;
                background: linear-gradient(126deg, #01bbff, #9544c4, #e503a3);
                position: absolute;
                top:0;
                z-index: 1;
                // border-radius: 100px;
                filter: blur(12px);
                opacity: 0.4;
            }
        }

        &:last-child {
            border: none;
        }

        &::before {
            content: '';
            width: 20px;
            height: 20px;
            display: flex;
            margin: auto;
            position: relative;
            z-index: 2;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 50% 50%;
        }
    }

    span {
        // @extend .visually-hidden;
        font-size: 11px;
        position: relative;
        z-index: 2;
    }
}

#nav-map {
    &:before {
        background-image: url(#{$assets-path}/map.svg);
    }
}

#nav-explore {

    &:before {
        background-image: url(#{$assets-path}/explore.svg);
    }

    &.active {
        &:before {
            animation: spin 0.8s ease-in-out 1;
        }
    }
}

#nav-add {
    &:before {
        background-image: url(#{$assets-path}/plus-circle.svg);
    }

    // &.active {
    //     &:after {
    //         animation: spin 1.4s ease-out 3;
    //     }
    // }

    // &:after {
    //     content: '';
    //     width: 23px;
    //     height: 23px;
    //     display: flex;
    //     margin: auto;
    //     background: linear-gradient(236deg, #01bbff, #9544c4, #e503a3);
    //     position: absolute;
    //     top: 9px;
    //     z-index: 1;
    //     border-radius: 100px;
    // }
}

#nav-filter:before {
    width: 26px;
    height: 26px;
    background-image: url(#{$assets-path}/filter.svg);
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}