// Venues and Events
.list {
    // margin-top: 2rem;
    border-top: 1px solid #eee;
    li {
        list-style: none;
        padding: 8px 0;
        margin: 0;
        display: flex;
        border-bottom: 1px solid #eee;
        gap: 12px;
        width: 100%;
        background-color: #fff;
    }
}

#content-explore {
    .switch-wrap {
        color: var(--primary-color);
        justify-content: flex-end;
        margin-bottom: 10px;
    }
}

// venue specific class in venue list
.venue {
    align-items: center;
    &::after {
        content: '';
        width: 15px;
        height: 15px;
        display: inline-block;
        transform: rotate(-90deg);
        background: url(#{$assets-path}/down-arrow-grey.svg) 50% 50% no-repeat;
        background-size: contain;
    }
}

#venue-details {
    font-size: 12px;
}

#barsUl {
    a {
        display: flex;
        width: 100%;
        color: inherit;
        text-decoration: none;
        align-items: center;
        gap: 20px;
    }
}

.item-name {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    margin: 0;
    font-weight: 600;
    color: var(--primary-color);
}

.item-img {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 8px;
    overflow: hidden;
    margin: 0;
    padding: 0;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.distance-display {
    &::before {
        content: '';
        width: 10px;
        height: 10px;
        display: inline-block;
        background: #fff url(#{$assets-path}/pin.svg) 50% 50% no-repeat;
        background-size: contain;
    }
}

.distance-display,
.open-now-status {
    font-size: 12px;
    color: #666;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 4px;
}

.open-now-status {
    &::before {
        content: '';
        width: 7px;
        height: 7px;
        border-radius: 30px;
        display: inline-block;
        background: rgb(var(--green));
    }
    &.closed {
        &::before {
            background: rgb(var(--red));
        }
    }
}

.venue-particulars {
    display: flex;
    gap: 12px;
    align-items: flex-start;
    position: relative;
}

#venue-details .venue-particulars {
    padding: 0 12px;
    margin: 10px 0 24px 0;
    .accordion {
        display: inline-flex;
    }
    .open-now-status,
    .venue-rating,
    .distance-display {
        color: var(--primary-color);
        font-size: 14px;
    }
    .distance-display {
        &::before {
            width: 12px;
            height: 12px;
        }
    }
}

.feature-block {
    display: flex;
    flex: 1;
    padding: 12px;
    border-radius: 8px;
    gap: 4px;
    justify-content: center;
    border: 1px solid #eee;
}

.trigger .open-now-status {
    &::after {
        content: '';
        display: inline-block;
        margin: 0 4px 0 0;
        width: 12px;
        height: 12px;
        display: inline-block;
        background: url(#{$assets-path}/down-arrow-grey.svg) 50% 50% no-repeat;
        background-size: contain;
    }
}

#opening-hours-wrap {
    position: absolute;
    z-index: 1;
    background: #fff;
    left: 0px;
    top: 50px;
    width: 100%;
    &.show {
        overflow: visible;
    }
}

#opening-hours {
    padding: 20px;
    line-height: 2;
    font-size: 16px;
}

.card-items {
    display: flex;
    overflow-x: scroll;
    gap: 12px;
    padding: 0 16px 20px 16px;
    .card-item {
        display: flex;
        margin-top: 20px;
        padding-top: 20px;
        gap: 12px;
        width: 90%;
        min-height: 110px; // prevents height issue with truncted text
        padding: 12px;
        flex-shrink: 0;
        border-radius: 12px;
        color: var(--primary-color);
        // background-color: #fff;
        // box-shadow: 0 3px 20px rgba(0, 0, 0, .1);
        border: 1px solid #eee;
        background-color: #F6F8F8;
    }
}

.item-img {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 8px;
    overflow: hidden;
    margin: 0;
    padding: 0;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.item-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 3px;
}

.event-item {
    .venue-name {
        @extend .item-name;
    }
}

.item-name,
.event-date,
.event-description,
.event-link {
    font-size: 14px;
}

.event-date {
    text-transform: uppercase;
    margin-bottom: 5px;
    color: #666;
}

.event-description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 5px;
}

.sheet-header {
    width: 100%;
    padding: 16px 30px 6px 16px;
}

#venue-sheet-venue-name {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.image-gallery {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: repeat(auto-fill, minmax(56%, 2fr));
    padding: 10px;
    overflow-x: auto;
    width: 100%;
    gap: 10px;
    height: 260px;
    // flex-shrink: 0;
    .gallery-image {
        width: 100%;
        object-fit: cover;
        min-width: 46vw;
        // max-height: 130px; /* need for mobile which struggles with grd layout */
        border-radius: 10px;
        height: 100%;
        min-height: 0;
        transition: all 0.3s ease;
        &:nth-child(3n + 1) {
            grid-row: span 2;
            max-height: none
        }
    }
}

.full-size-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    .gallery-image {
        max-width: 90%;
        max-height: 90%;
        object-fit: contain;
    }
    // Gallery close-button
    .close-button {
        position: fixed;
        z-index: 2000;
        top: 10px;
        right: 10px;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        font-size: 24px;
        padding: 5px;
        cursor: pointer;
        border-radius: 30px;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 32px;
        display: block;
    }
}

.no-results {
    margin: 20px 0;
}