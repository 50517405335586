.sticky {
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 16px;
  background: #fff;
}


.responsive-tabs-content {
  padding: 0 16px 100px 16px;

  &:after {
    display: block;
    content: '';
    width: 100%;
    height: 100px;
    position: absolute;
    bottom: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  }
}


.responsive-tabs {
  display: flex;
  width: 100%;
  margin: 0;
  position: relative;
  overflow: hidden;
  transition: all .3s ease;
  background: #EEEEEF;
  // height: 2rem;
  padding: 0.125rem;
  border-radius: 30px;


  li {

    grid-row: 1;
    display: flex;
    text-align: center;
    cursor: pointer;
    flex: 1 1 0px;
    position: relative;
    z-index: 2;
    transition: inherit;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    margin: 0;


    &.active {

      // a {
      //   font-weight: bold;
      // }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -2;
        background-color: #fff;
        transition: inherit;
        border-radius: 30px;
        border: 0.5px solid rgba(0, 0, 0, 0.04);
        box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.04), 0px 3px 8px rgba(0, 0, 0, 0.12);

      }
    }

    a {
      color: #222;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 10px;
      text-decoration: none;
    }
  }
}

.responsive-tabs-panel {
  display: none;
}