@import "variables";
@import "normalize";
@import "map";
@import "nav";
@import "type";
@import "venues";
@import "bot-modal";
@import "toastify";
// @import "slider"; // not use atm
@import "form";
@import "tabs";
@import "accordion";

html {
  box-sizing: border-box;
  min-height: -webkit-fill-available;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  padding: 0;
  margin: 0;
  color: #fff;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  overflow: hidden;
  // font-family: 'Roboto', sans-serif;
  font-family: 'Poppins', sans-serif;
  background-color: var(--primary-color);
}

// JS finds the height of this to get around safari iphone bug
#app {
  position: relative;
  height: 100vh;
  height: 100dvh;
  // max-width: 768px;
  overflow: hidden;
}

.terms {
  p {
    margin: 0;
    color: #8F8E98;
    font-size: 12px;
  }
}

.content-view {
  width: 100%;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
  opacity: 0;
  transition: opacity 0.25s ease-out;

  &.active {
    z-index: 2;
    opacity: 1;
  }
}

.contents {

  height: 100%;
  overflow-y: auto;
  background-color: #fff;

  &.padding {
    padding: 20px 20px 20px 20px;
  }
}




.logo {
  margin: 0 0 0 12px;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: Helvetica, sans-serif;
}




#debug-location {
  position: relative;
  z-index: 10;
  display: none;

  .debug-mode & {
    display: block;
  }

  .form-group {
    display: flex;
    gap: 10px;

    input {
      width: 100%;
      font-size: 1rem;
    }
  }
}