
.btn {
    font-weight: 500;
    font-size: 0.875rem;
    border-radius: 100px;
    border: 0;
    position: relative;
    padding: 8px 25px;
    outline: none;
    box-sizing: border-box;
    height: var(--btn-height);
    line-height: 1;
    transition: all 0.4s ease;

    &:hover,
    &:focus {
        color: #fff;
        outline: 0;
        outline: none;
    }
}
.btn-primary {
    color: #fff;
    border-color: var(--primary-color);
    background: var(--primary-color);

    &.disabled,
    &:disabled {
        opacity: 0.2;
        border-color: var(--disabled-grey);
        background: var(--disabled-grey);
    }
}
.form-group {
    margin-bottom: 15px;
}

.form-group-line {
    margin-bottom: 15px;
    padding-top: 15px;
    border-top: 1px solid #eee;
}

label {
    color: var(--primary-color);
    display: block;
    font-weight: 500;
    margin: 0 0 5px 0;
    font-size: 14px;
    // transition: all 0.2s ease-in;
}

// label+.toggle-checkboxes {
//     margin-top: 10px;
// }

.toggle-checkboxes {

    display: flex;
    gap: 12px;

    .toggle-checkbox {
        display: flex;
        width: 100%;
    }

    .toggle-description {
        color: var(--primary-color);
        font-weight: 500;
        font-size: 14px;
        border-radius: 8px;
        text-align: center;
        // min-width: 90px;
        min-width: 7rem;
        width: 100%;
        margin: 0;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: var(--btn-height);
        padding: 5px 15px;
        vertical-align: middle;
        // transition: all 0.1s ease-out; // this causes issues
        // border: 1px solid rgba(var(--primary-color), 0.2);
        border: 1px solid #C9C6D9;
        background-color: #fff;
    }
}

// checked radio
.hidden-input-field:checked+.toggle-description {
    border: 1px solid var(--primary-color);
    background-color: #F3F4FE;
}

#submit-section {
    display: none;
}
#good-vibes-selected {
    display: none;
    .form-group-line {
        margin-bottom: 5px;
    }
}

.good-vibes-check {
    .toggle-description {
        color: rgb(var(--green));
        background-color: transparent;
        border: 1px solid rgb(var(--green));
    }
    .hidden-input-field:checked+.toggle-description {
        color: #fff;
        border-color: rgb(var(--green));
        background-color: rgb(var(--green));
    }
}

.bad-vibes-check {
    .toggle-description {
        color: rgb(var(--red));
        background-color: transparent;
        border: 1px solid rgb(var(--red));
    }
    .hidden-input-field:checked+.toggle-description {
        color: #fff;
        border-color: rgb(var(--red));
        background-color: rgb(var(--red));
    }
}





// iphone style toggle switch
.is-toggle-switch {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0 !important;
    padding: 0 !important;
    font-weight: normal;
    font-size: 14px;

    &:hover {
        .toggle-switch {
            background: #eee;
        }
    }

    &.toggle-switch-disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }

    // Disabled state without using class above
    .hidden-input-field[disabled]+.toggle-switch {
        opacity: 0.2;
        cursor: not-allowed;
    }

    .toggle-switch {
        display: inline-block;
        background: #f4f4f4;
        border: 1px solid #ccc;
        border-radius: 16px;
        width: 44px;
        height: 26px;
        flex-shrink: 0;
        position: relative;
        vertical-align: middle;
        transition: background 0.25s;

        &:before {
            content: "";
            display: block;
            background: #fff;
            // border: 1px solid rgba(0, 0, 0, 0.1);
            box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.15), 0 1px 1px 0 rgba(0, 0, 0, 0.16), 0 3px 1px 0 rgba(0, 0, 0, 0.1);
            border-radius: 50%;
            width: 24px;
            height: 24px;
            position: absolute;
            top: 0px;
            left: 0px;
            transition: left 0.25s ease-out;
        }
    }

    .hidden-input-field:checked+.toggle-switch {
        background: rgb(var(--green));
        border-color: rgb(var(--green));

    }

    .hidden-input-field:checked+.toggle-switch:before {
        left: 18px;
    }

    .toggle-label {
        margin-left: 10px;
    }
}

.hidden-input-field {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}



.checkbox-special {
    overflow-x: auto;
    padding-bottom: 10px;
    gap: 6px;

    .toggle-checkbox {
        width: auto;
        gap: 5px;
    }
    .toggle-description {
        // border-radius: 8px;
        flex-direction: column;
        height: auto;
        font-size: 10px;
        font-weight: 400;
        padding: 6px 3px;
        justify-content: flex-start;

        &:before {
            content: "";
            display: block;
            background: #fff;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            position: absolute;
            top: 13px;
            left: 53%;
            scale: 0;
            background: rgb(var(--green)) url(../img/checkbox-tick-white.svg) 50% 50% no-repeat;
            background-size: 9px auto;
            transition: scale 0.1s ease;
            // transform: scale(1) rotate(0deg) translate3d(0,0,0) 0.1s ease;
        }
    }

    .checkbox-icon {
        font-size: 24px;
    }

    // if checked
    .hidden-input-field:checked+.toggle-description {
        &:before {
            opacity: 1;
            scale: 1;
        }
    }
}

.switch-wrap {
    display: flex;
    gap: 12px;
    align-items: center;
    padding: 3px 0;
    justify-content: space-between;

    label {
        color: inherit;
        font-size: 14px;
        margin: 0;
    }
}


.form-control {
    color: var(--primary-color);
    font-weight: 400;
    font-size: 1rem;
    // font-size: 0.875rem;
    display: block;
    position: relative;
    padding: 6px 8px;
    height: var(--btn-height);
    border: 1px solid var(--form-border-color);
    border-radius: var(--input-border-radius);
    outline: none;
    width: 100%;
    line-height: 1.2em;
    z-index: 1;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #fff;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    // @include placeholder-light;
    &:focus {
        border: 2px solid var(--form-border-color);
        border-bottom-color: #ddd;
        // color: $is-black;
        background-color: #fff;
        outline: 0;
        box-shadow: none;
    }
}


.custom-select {
    height: var(--btn-height);
    padding-right: 58px;
    box-shadow: none;
    // background: transparent;
    // Resets Chrome/Safari and allows styling
    -webkit-appearance: none;
    // Removes default arrow from firefox
    text-indent: 0.01px;
    text-overflow: "";
    -moz-appearance: none;
    // Removes default arrow from IE10+
    // &::-ms-expand {
    //     display: none;
    // }
    background-image:url(#{$assets-path}/select-dropdown-arrow.svg);
    background-size: contain;
    background-position: 100% 50%;
    background-repeat: no-repeat;
}
